import React, { Component } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import _ from 'underscore';

import spotify from '../helpers/spotifyHelper';
import db from '../helpers/dynamoHelper';
import error from '../helpers/errorHelper';

import AlertPopup from '../misc_components/AlertPopup';

let lastNumImportedSongs = -1;
let lastImportedPlaylist = '';
let lastFailedPlaylist = '';

class SpotifyImportButton extends Component {

  constructor() {
    super();

    this.state = {
      showSuccessAlert: false,
      numImportedSongs: -1,
    };

    this.getDatabase = this.getDatabase.bind(this);
    this.addSongsLocal = this.addSongsLocal.bind(this);

    this.toggleSuccessAlert = this.toggleSuccessAlert.bind(this);
    this.toggleErrorAlert = this.toggleErrorAlert.bind(this);
  }

  getDatabase() {
    this.props.getDatabase();
  }

  addSongsLocal(songs) {
    this.props.addSongsLocal(songs);
  }

  toggleSuccessAlert() {
    this.setState({showSuccessAlert: !this.state.showSuccessAlert});
  }

  toggleErrorAlert() {
    this.setState({showErrorAlert: !this.state.showErrorAlert});
  }

  async onPlaylistSelect(playlist) {
    //console.time('Importing playlist');
    const songs = this.props.songs;
    const currentSongIDs = _.pluck(songs, 'id');
    let playlistSongs = '';
    if(playlist === 'spotifySavedTracksTagifyer') {
      playlistSongs = await spotify.getSavedTracks();
    }
    else {
      playlistSongs = await spotify.getPlaylistSongs(playlist);
    }
    const songsToImport = _.reject(playlistSongs, (iterSong) => {return _.contains(currentSongIDs, iterSong.id)});

    const maxSongs = this.props.maxSongs;
    const numCurrentSongs = (songs) ? songs.length : 0;
    const numImportSongs = (songsToImport) ? songsToImport.length : 0;
    if((numCurrentSongs + numImportSongs) > maxSongs) {
      const message = 'Already have ' + songs.length + ' songs, so importing '
        + songsToImport.length + ' new songs from playlist ' + playlist
        + ' would exceed the maximum number of ' + maxSongs + ' songs';
      error.handle(message, 'Import would exceed song limit');
      lastFailedPlaylist = playlist;
      this.toggleErrorAlert();
    }
    else {
      this.addSongsLocal(songsToImport);
      db.addSongs(songsToImport)
      .then(() => {
        //console.info('Imported playlist ' + playlist + ' into the database');
        lastNumImportedSongs = numImportSongs;
        if(playlist === 'spotifySavedTracksTagifyer') {
          lastImportedPlaylist = 'Saved Tracks'
        }
        else {
          lastImportedPlaylist = 'playlist ' + playlist;
        }
        this.toggleSuccessAlert();
      })
      .catch((err) => {
        const message = 'Failed to update database with new playlist';
        error.handle(message, err);
      });
    }
    //console.timeEnd('Importing playlist');
  }

  render () {

    const playlists = _.keys(this.props.playlists);

    const importMarkup =
      <DropdownButton
        variant='tagifyer-menu'
        title='Import Songs'
      >
        <Dropdown.Item
          key='spotifySavedTracksTagifyer'
            onSelect={async () => await this.onPlaylistSelect('spotifySavedTracksTagifyer')}
        >
          Saved Tracks
        </Dropdown.Item>

        <Dropdown.Divider/>

        {playlists && playlists.map((playlist) =>
          <Dropdown.Item
            key={playlist}
            onSelect={async () => await this.onPlaylistSelect(playlist)}
          >
            {playlist}
          </Dropdown.Item>
        )}
      </DropdownButton>;

    const successMessageMarkup =
      <AlertPopup
        title='Playlist Import Successful'
        bodyText={'Imported ' + lastNumImportedSongs + ' songs from ' + lastImportedPlaylist}
        show={this.state.showSuccessAlert}
        toggleAlert={this.toggleSuccessAlert}
      />;

    const errorMessageMarkup =
      <AlertPopup
        title='Playlist Import Failed'
        bodyText={'Failed to import playlist ' + lastFailedPlaylist + ' because the limit of ' + this.props.maxSongs + ' would be surpassed. No songs were imported.'}
        show={this.state.showErrorAlert}
        toggleAlert={this.toggleErrorAlert}
      />;

    return (
      <>
        {importMarkup}
        {successMessageMarkup}
        {errorMessageMarkup}
      </>
    );
  }
}

export default SpotifyImportButton;
