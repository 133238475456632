import React, { Component } from 'react';

import ConfirmationButton from '../../misc_components/ConfirmationButton';

class VisibleSongsDelete extends Component {

  constructor(props) {
    super(props);

    this.deleteSongs = this.deleteSongs.bind(this);
  }

  deleteSongs() {
    this.props.deleteSongs(this.props.visibleSongs);
  }

  render () {
    const deleteSongsMarkup =
      <ConfirmationButton
        variant='outline-tagifyer-pink'
        buttonName={'Delete Current Songs (' + this.props.visibleSongsCount + ')'}
        title='Delete All Visible Songs'
        bodyText='Confirm you want to delete all the songs currently visible from Tagifyer. No songs will ever be deleted in Spotify.'
        functionToExecute={async () => await this.deleteSongs()}
      />

    return (
      <div className='deleteAllButton'>
        {deleteSongsMarkup}
      </div>
    );
  }
}

export default VisibleSongsDelete;
