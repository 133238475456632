import React, { Component } from "react";
import Button from 'react-bootstrap/Button';

class SpotifyLoginButton extends Component {

  handleLoginClick = () => {
    let stage = 'no stage';
    if(process.env.NODE_ENV === 'development') {
      stage = 'latest';
    }
    else if(process.env.NODE_ENV === 'production') {
      stage = 'production';
    }

    window.open(
      'https://u68mnk151a.execute-api.us-east-1.amazonaws.com/'
      + stage
      + '/login',
      '_self');
  }

  render () {
    return (
      <Button
        variant='tagifyer-menu'
        onClick={this.handleLoginClick}
      >
        Login with Spotify
      </Button>
    );
  }
}

export default SpotifyLoginButton;
