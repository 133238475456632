import React, { Component } from 'react';

import IdleTimer from 'react-idle-timer';
import ObjectHash from 'object-hash';

import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';

import _ from 'underscore';
import querySearch from 'stringquery';

// CSS
import './Main.css';

// Helpers
import spotify from './helpers/spotifyHelper';
import db from './helpers/dynamoHelper';
import error from './helpers/errorHelper';

// Components
import ConfirmationButton from './misc_components/ConfirmationButton';
import AlertPopup from './misc_components/AlertPopup';

// Logged Out
import Introduction from './guest/Introduction';

// Logged In
import Menu from './menu/Menu';
import Editor from './editor/Editor';

const timeoutMinutes = 15;
const maxTagLength = 25;

const defaultMaxTags = 5;
const defaultMaxSongs = 25000;
const powerUserMaxTags = 100;
const powerUserMaxSongs = 100000;

class Main extends Component {

  //============================================================
  // Lifecycle Functions
  //============================================================

  constructor(){
    super();

    // check for Spotify login redirect
    const query = querySearch(window.location.search);
    if(query.token) {
      this.login(query.token, query.session);
      window.history.replaceState({}, document.title, '/');
    }

    // pull state from localStorage if it exists

    // affinity filter state
    let plusAffinityFilter = localStorage.getItem('plusAffinityFilter');
    if(plusAffinityFilter === null || plusAffinityFilter === 'true') {
      plusAffinityFilter = true;
    }
    else {
      plusAffinityFilter = false;
    }

    let neutralAffinityFilter = localStorage.getItem('neutralAffinityFilter');
    if(neutralAffinityFilter === null || neutralAffinityFilter === 'true') {
      neutralAffinityFilter = true;
    }
    else {
      neutralAffinityFilter = false;
    }

    let minusAffinityFilter = localStorage.getItem('minusAffinityFilter');
    if(minusAffinityFilter === null || minusAffinityFilter === 'true') {
      minusAffinityFilter = true;
    }
    else {
      minusAffinityFilter = false;
    }

    // setup state
    this.state = {
      user: 'Guest',
      authenticated: false,
      userType: 'standard',
      lastFeatureSeen: -1,
      // this is loaded from local storage once tags get pulled
      // makes sure the tag under edit actually exists
      tagUnderEdit: null,
      spotifyPlaylists: ['No playlists imported'],
      affinityFilter: {
        'plus': plusAffinityFilter,
        'neutral': neutralAffinityFilter,
        'minus': minusAffinityFilter,
      },
      tags: [],
      maxTags: defaultMaxTags,
      maxSongs: defaultMaxSongs,
      showMaxTagAlert: false,
      showNoTagAlert: false,
      showNewFeatureAlert: false,
    };

    // initialize idle timer
    this.idleTimer = null;
    this.onActive = this._onActive.bind(this);
    this.onIdle = this._onIdle.bind(this);
    this.timeout = 1000*60*timeoutMinutes;

    // bind functions
    this.deleteSongs = this.deleteSongs.bind(this);
    this.changeAffinity = this.changeAffinity.bind(this);
    this.changeRating = this.changeRating.bind(this);
    this.handleTagTextKeyPress = this.handleTagTextKeyPress.bind(this);
    this.toggleAffinityFilter = this.toggleAffinityFilter.bind(this);
    this.getDatabase = this.getDatabase.bind(this);
    this.exportTag = this.exportTag.bind(this);
    this.addSongsLocal = this.addSongsLocal.bind(this);
    this.toggleMaxTagAlert = this.toggleMaxTagAlert.bind(this);
    this.toggleNoTagAlert = this.toggleNoTagAlert.bind(this);
    this.toggleNewFeatureAlert = this.toggleNewFeatureAlert.bind(this);

    // refs
    this.newTagInput = React.createRef();
  }

  //============================================================
  // Login Functions
  //============================================================

  login = async (token, session) => {
    spotify.setToken(token);
    const user = await spotify.getUser();
    db.setUser(user);
    db.setToken(token);
    db.setSession(session);

    const userInfo = await db.getUserInfo();

    let userType = 'normal';
    if(userInfo && userInfo.userType) {
      userType = userInfo.userType;
    }

    let maxTags = defaultMaxTags;
    let maxSongs = defaultMaxSongs;
    if(userType === 'powerUser') {
      maxTags = powerUserMaxTags;
      maxSongs = powerUserMaxSongs;
    }

    let lastSessionBirthday = -1;
    if(userInfo && userInfo.lastSessionBirthday) {
      lastSessionBirthday = userInfo.lastSessionBirthday;
    }

    this.setState({
      user: user,
      authenticated: true,
      userType: userType,
      maxTags: maxTags,
      maxSongs: maxSongs,
      lastSessionBirthday: lastSessionBirthday,
    });

    // Show new feature boxes if they haven't logged in for a while
    this.showNewFeatures(lastSessionBirthday);

    //console.time('Get playlists and song database');
    // Pull Spotify playlists in background
    const playlistsPromise = this.updatePlaylists();

    // Update song database to state/localStorage
    const songsPromise = this.updateSongDatabase();

    await Promise.all([playlistsPromise, songsPromise]);
    //console.timeEnd('Get playlists and song database');
  }

  logout = (type) =>  {
    if(!type || type !== 'noToken') {
      db.logoutUser();
    }
    this.setState({
      user: 'Guest',
      authenticated: false,
      userType: 'standard',
      maxTags: defaultMaxTags,
      maxSongs: defaultMaxSongs,
      lastSessionBirthday: -1,
    });
  };

  async _onActive(e) {
    // don't bother trying to refresh token if you're close to the 55 minute limit
    // Spotify max token age is 60 minutes, but tagifyer API won't try at 55 minutes
    const MAX_IDLE_MINUTES = 53;

    const elapsed_minutes = Math.floor(this.idleTimer.getElapsedTime()/1000/60);
    //console.info('User idled for ' + elapsed_minutes + ' minutes and just became active');
    if(this.state.authenticated) {
      if(elapsed_minutes < MAX_IDLE_MINUTES) {
        const newToken = await db.refreshToken();
        if(!newToken) {
          this.logout('noToken');
        }
        else {
          db.setToken(newToken);
        }
      }
      else {
        this.logout('noToken');
      }
    }
  }

  _onIdle(e) {
    //console.info('User went idle after ' + timeoutMinutes + ' minutes');
  }

  //============================================================
  // Page Load Functions
  //============================================================

  getNewFeatures(lastLoggedIn) {

    const features = [
      //{time: Date.now(), text: 'feature 2 text'},
    ];

    const newFeatures = _.filter(features, (feature) => {
      if(lastLoggedIn !== -1 && feature.time >= lastLoggedIn) {
        return true;
      }
    });

    const newFeaturesText = _.pluck(newFeatures, 'text');
    return newFeaturesText;
  }

  showNewFeatures(lastLoggedIn) {
    const newFeatures = this.getNewFeatures(lastLoggedIn);
    if(newFeatures.length) {
      this.toggleNewFeatureAlert();
    }
  }

  async updatePlaylists() {
    const userPlaylists = await spotify.getPlaylists();
    this.setState({spotifyPlaylists: userPlaylists});
  }

  async updateSongDatabase() {
    // Check to see if we need to pull the database
    let localStorageSongDatabase = localStorage.getItem('songDatabase');
    if(localStorageSongDatabase && localStorageSongDatabase !== 'undefined') {
      localStorageSongDatabase = JSON.parse(localStorageSongDatabase);
    }

    const remoteSignature = await db.getDatabaseSignature();
    const localSignature = this.calculateDatabaseSignature(localStorageSongDatabase);

    return Promise.all([localSignature, remoteSignature])
    .then(async (signatures) => {
      const localSignature = signatures[0];
      const remoteSignature = signatures[1];
      let databaseFetched;
      if (!remoteSignature) {
        //console.info('Remote database signature is null, fetching remote database');
        databaseFetched = await this.getDatabase();
      }
      else if(!localSignature) {
        //console.info('No local database, fetching remote database');
        databaseFetched = await this.getDatabase();
      }
      else if(localSignature !== remoteSignature) {
        error.log('Signatures don\'t match, fetching remote database');
        databaseFetched = await this.getDatabase();
      }
      else {
        //console.info('Signatures match, using database from local storage');
        databaseFetched = this.setState({songDatabase: localStorageSongDatabase});
      }
      await Promise.resolve(databaseFetched);
      const tags = await this.getTags(this.state.songDatabase);

      const tagUnderEdit = localStorage.getItem('tagUnderEdit');
      let tagToSet = ''
      if(_.contains(tags, tagUnderEdit)) {
        tagToSet = tagUnderEdit;
      }
      else if(tags.length) {
        tagToSet = tags[0];
      }

      this.selectTagToEdit(tagToSet);
      await this.setState({tags: tags});
    })
    .catch((err) => {
      const message = 'Failed to compare the local and remote database signatures';
      error.handle(message, err);
    });
  }

  //============================================================
  // Database Sync Functions
  //============================================================

  calculateDatabaseSignature(database) {
    return ObjectHash(database);
  }

  async updateDatabase(newDatabase) {
    this.setState(
      {songDatabase: newDatabase},
      async () => {
        //console.info('Loaded the remote database');
        localStorage.setItem('songDatabase', JSON.stringify(newDatabase));
        await db.updateDatabaseSignature(this.calculateDatabaseSignature(newDatabase));
      }
    );
  }

  async getDatabase() {
    const userDatabase = await db.getDatabase();
    if(!userDatabase) {
      error.log('Remote database fetched, but was empty or failed to load');
      await this.updateDatabase([]);
    }
    else {
      await this.updateDatabase(userDatabase);
    }
  }

  async getTags(database) {
    if(!database) {
      return null;
    }
    const minusTags = database.map((song) => {
      return song.minusTags;
    });
    const plusTags = database.map((song) => {
      return song.plusTags;
    });

    // each song returns an array of tags, flatten each array and then do union
    // passing 'true' to _.flatten means only flatten one level
    return _.compact(_.union(_.flatten(minusTags, true), _.flatten(plusTags, true)));
  }

  async addSongsLocal(songs) {
    const songsToAdd = _.map(songs, (song) => {
      const songItem = {
        user: this.state.user,
        id: song.id,
        name: song.title,
        artist: song.artist,
        plusTags: [],
        minusTags: [],
        rating: 0,
        addedTime: song.addedTime
      };
      return songItem;
    });
    const newDatabase = this.state.songDatabase.concat(songsToAdd);
    await this.updateDatabase(newDatabase);
  }

  //============================================================
  // Tag to Edit Functions
  //============================================================

  selectTagToEdit(tag) {
    localStorage.setItem('tagUnderEdit', tag);
    this.setState({tagUnderEdit: tag});
  }

  toggleMaxTagAlert() {
    this.setState({showMaxTagAlert: !this.state.showMaxTagAlert});
  }

  toggleNoTagAlert() {
    this.setState({showNoTagAlert: !this.state.showNoTagAlert});
  }

  toggleNewFeatureAlert() {
    this.setState({showNewFeatureAlert: !this.state.showNewFeatureAlert});
  }

  makeNewTag() {
    let newTags = this.state.tags;
    let newTag = this.newTagInput.current.value;
    if(newTag.length === 0) {
      return;
    }
    newTag = newTag.substring(0, Math.min(maxTagLength, newTag.length)).toLowerCase();
    if(_.contains(newTags, newTag)){
      error.log('Tag ' + newTag + ' already exists');
    }
    else if(newTags.length >= this.state.maxTags) {
      this.toggleMaxTagAlert();
      error.log('Already have the maximum number of tags!');
    }
    else {
      //console.info('Creating new tag ' + newTag);
      newTags.push(newTag);
      this.setState({
        tags: newTags,
        tagUnderEdit: newTag,
      });
    }
  }

  async deleteTag() {
    //console.time('Deleting tag');
    this.changeAffinity(this.state.songDatabase, 'neutral');
    const tags = this.state.tags;
    await this.setState({tags: _.without(tags, this.state.tagUnderEdit)});
    this.setState({tagUnderEdit: this.state.tags[0]});
    //console.timeEnd('Deleting tag');
  }

  handleTagTextKeyPress(target) {
    if(target.charCode===13) {
      this.makeNewTag();
    }
  }

  //============================================================
  // Filter Functions
  //============================================================

  toggleAffinityFilter(affinity) {
    //console.time('Toggling affinity');
    let newAffinityFilter = this.state.affinityFilter;
    const oldAffinity = newAffinityFilter[affinity];
    newAffinityFilter[affinity] = !oldAffinity;

    // write to local storage as well
    const storageName = affinity + 'AffinityFilter';
    localStorage.setItem(storageName, !oldAffinity);

    // write to state
    this.setState(newAffinityFilter);
    //console.timeEnd('Toggling affinity');
  }

  //============================================================
  // Editing Functions
  //============================================================

  async deleteSongs(songs) {
    const database = this.state.songDatabase;
    const deleteIDs = _.pluck(songs, 'id');
    const newDatabase = _.reject(database, (iterSong) => {
      return _.contains(deleteIDs, iterSong.id)
    });
    return await db.deleteSongs(songs)
    .then(() => {
      this.setState({songDatabase: newDatabase}, () => {
        localStorage.setItem('songDatabase', JSON.stringify(this.state.songDatabase));
        db.updateDatabaseSignature(this.calculateDatabaseSignature(newDatabase));
      });
    })
    .catch((err) => {
      const message = 'Failed to delete songs from the database';
      error.handle(message, err);
    });
  }

  async changeAffinity(songs, affinity) {
    const tag = this.state.tagUnderEdit;

    // Make sure a tag is being edited
    if(!tag) {
      this.toggleNoTagAlert();
      error.log('Called changeAffinity, but no tag under edit!');
      return;
    }

    const database = this.state.songDatabase;

    let songsInDatabaseFormat = [];
    _.each(songs, (song) => {
      // find the song in the database array
      const songIDs = _.pluck(database, 'id');
      const songIndex = _.indexOf(songIDs, song.id);
      if(
            ((affinity === 'plus') && _.contains(database[songIndex].plusTags, tag))
        ||  ((affinity === 'minus') && _.contains(database[songIndex].minusTags, tag))
      ) {
        //console.info(song.name + ' already had affinity ' + affinity + ' set for tag ' + tag);
        return;
      }

      // delete the tag from the opposite affinity if necessary
      if(affinity !== 'plus') {
        database[songIndex].plusTags = _.without(database[songIndex].plusTags, tag);
      }
      if(affinity !== 'minus') {
        database[songIndex].minusTags = _.without(database[songIndex].minusTags, tag);
      }

      // add the tag to the new affinity
      if(affinity === 'plus') {
        database[songIndex].plusTags.push(tag);
      }
      else if(affinity === 'minus') {
        database[songIndex].minusTags.push(tag);
      }

      // push to array that will update the database
      songsInDatabaseFormat.push({
        title: song.name,
        id: song.id,
        plusTags: database[songIndex].plusTags,
        minusTags: database[songIndex].minusTags,
        rating: song.rating,
        artist: song.artist,
      });

      //console.info('Affinity of song ' + song.name + ' is now ' + affinity);

    });

    db.addSongs(songsInDatabaseFormat);

    // update the local state
    this.setState(
      {songDatabase: database},
      async () => {
        localStorage.setItem('songDatabase', JSON.stringify(this.state.songDatabase));
        await db.updateDatabaseSignature(this.calculateDatabaseSignature(database));
      }
    );
  }

  async changeRating(songs, rating) {
    let newRating = rating;
    const database = this.state.songDatabase;

    let songsInDatabaseFormat = [];
    _.each(songs, (song) => {
      const songIDs = _.pluck(database, 'id');
      const songIndex = _.indexOf(songIDs, song.id);
      database[songIndex].rating = newRating;

      songsInDatabaseFormat.push({
        title: song.name,
        id: song.id,
        plusTags: song.plusTags,
        minusTags: song.minusTags,
        rating: newRating,
        artist: song.artist,
      });

      //console.info('Rating of song ' + song.name + ' is now ' + newRating);

    });

    db.addSongs(songsInDatabaseFormat);

    // update the local state
    this.setState(
      {songDatabase: database},
      () => {
        localStorage.setItem('songDatabase', JSON.stringify(this.state.songDatabase));
        db.updateDatabaseSignature(this.calculateDatabaseSignature(database));
      }
    );
  }

  //============================================================
  // Export Function
  //============================================================

  async exportTag() {
    const tag = this.state.tagUnderEdit;
    if(!tag)
    {
      error.handle('Need to select a tag to export!');
    }
    const playlistNameBase = 'tagifyer_' + tag;
    let count = 0;
    let playlistName = playlistNameBase;
    while(_.contains(_.keys(this.state.spotifyPlaylists), playlistName)) {
      count++;
      playlistName = playlistNameBase + '_' + count;
    }
    const database = this.state.songDatabase;
    const songsToExport = _.filter(database, (song) => {
      return _.contains(song.plusTags, tag);
    });
    await spotify.createPlaylist(playlistName, songsToExport);
    await this.updatePlaylists();
    //console.info('Exported playlist ' + playlistName);
    return playlistName;
  }

  //============================================================
  // Render HTML
  //============================================================

  render() {

    //==========================================================
    // Header Markup
    //==========================================================

    const titleMarkup =
      <div className='title'>
        <h1>Tagifyer</h1>
      </div>;

    const menuMarkup =
      <Menu
        authenticated={this.state.authenticated}
        playlists={this.state.spotifyPlaylists}
        songs={this.state.songDatabase}
        maxSongs={this.state.maxSongs}
        logout={this.logout}
        getDatabase={this.getDatabase}
        addSongsLocal={this.addSongsLocal}
        exportTag={this.exportTag}
      />;

    const headerMarkup =
      <div className='header'>
        {titleMarkup}
        {menuMarkup}
      </div>;

    //==========================================================
    // Content Markup
    //==========================================================

    let currentTagsMarkup = '';
    const tags = this.state.tags;
    if(_.size(tags)) {
      currentTagsMarkup = _.sortBy(tags).map((tag) => {
        let variant = 'tagifyer-blue';
        if(tag !== this.state.tagUnderEdit) {
          variant = 'outline-' + variant;
        }
        return <Button
          className='tagButton'
          variant={variant}
          key={tag}
          onClick={() => this.selectTagToEdit(tag)}
        >
          {'#' + tag}
        </Button>
      }
    )};

    const createTagMarkup =
      <>
      <InputGroup>
        <FormControl
          ref={this.newTagInput}
          maxLength={maxTagLength}
          aria-label='createTag'
          type='text'
          placeholder='Enter new tag'
          onKeyPress={this.handleTagTextKeyPress}
        />
        <InputGroup.Append>
          <Button
            variant='tagifyer-menu'
            onClick={() => this.makeNewTag()}
          >
            Create!
          </Button>
        </InputGroup.Append>
      </InputGroup>
      </>

    const deleteTagMarkup = (this.state.tagUnderEdit) ?
      // if there is a tag selected
        <ConfirmationButton
          variant='outline-tagifyer-pink'
          buttonName='Delete Current Tag'
          title='Delete Tag'
          bodyText={'Confirm you want to delete tag #' + this.state.tagUnderEdit}
          functionToExecute={() => this.deleteTag()}
        />
      :
      // no tag to delete if no tag selected
        '';

    let tagsUnderEditMarkup = '';
    if(this.state.tagUnderEdit)
    {
      tagsUnderEditMarkup =
        <div className='tagUnderEditText'>
          Currently editing tag&nbsp;
          <div className='tagUnderEdit'>
            {'#' + this.state.tagUnderEdit}
          </div>
        </div>;
    }
    else
    {
      tagsUnderEditMarkup =
        <div className='tagUnderEditText'>
          Create a new tag to get started!
        </div>;
    }

    const tagsMarkup =
      <div className='tags'>
        <div className='tagsSubheading'>
          <h3>
            Tags
          </h3>
        </div>
        <div className='createTagRow'>
          {createTagMarkup}
          {tagsUnderEditMarkup}
          {deleteTagMarkup}
        </div>
        <div className='tagList'>
          <div className='tagsLabel'>
            Your Tags - Select a different tag to begin editing associations towards that tag
          </div>
          <div className='tagListButtons'>
            {currentTagsMarkup}
          </div>
        </div>
      </div>;

    const editorMarkup =
      <Editor
        songs={this.state.songDatabase}
        tags={this.state.tags}
        affinityFilter={this.state.affinityFilter}
        tagUnderEdit={this.state.tagUnderEdit}
        deleteSongs={this.deleteSongs}
        changeAffinity={this.changeAffinity}
        changeRating={this.changeRating}
        toggleAffinityFilter={this.toggleAffinityFilter}
      />;

    const introductionMarkup =
      <Introduction/>;

    const contentMarkup = (!this.state.authenticated) ?
      // if not logged in
      <div className='content'>
        {introductionMarkup}
      </div>
      : // or if logged in
      <div className='content'>
        {tagsMarkup}
        {editorMarkup}
      </div>;

    const footerMarkup =
      <div className='footer'>
        <div>
          <img
            className='spotifyLogo'
            src='spotify_logo.png'
            alt='Spotify Logo'
          />
          <a href='https://www.spotify.com/us/premium/'>Get Spotify Premium</a>
        </div>
        <div>
          Questions or issues? <a href='mailto:tagifyer@gmail.com'>Let us know</a>
        </div>
      </div>;

    //==========================================================
    // Invisible Markup
    //==========================================================

    const standardText = 'You have already reached the maximum of ' + defaultMaxTags + ' tags per user. If you\'re a power user (and not a bot) please contact tagifyer@gmail.com to get this limit increased.';
    const powerUserText = 'You have reached the maximum of ' + powerUserMaxTags +  ' tags for a power user. Contact tagifyer@gmail.com to discuss your usage and increasing your limit';
    const bodyText = (this.state.userType === 'powerUser') ? powerUserText : standardText;

    const maxTagAlertMarkup =
      <AlertPopup
        title='Maximum Tags Reached'
        bodyText={bodyText}
        show={this.state.showMaxTagAlert}
        toggleAlert={this.toggleMaxTagAlert}
      />;

    const noTagAlertMarkup =
      <AlertPopup
        title='No Tag Selected'
        bodyText={'Can\'t add tags to songs when no tag is being edited'}
        show={this.state.showNoTagAlert}
        toggleAlert={this.toggleNoTagAlert}
      />;

    const newFeatures = this.getNewFeatures(this.state.lastSessionBirthday);
    const newFeaturesList = newFeatures.map((feature) => {
      return <li key={feature}>{feature}</li>
    });
    const newFeaturesMarkup = <ul className='popupList'>{newFeaturesList}</ul>;

    const newFeatureAlertMarkup =
      <AlertPopup
        title='New Features'
        bodyText={newFeaturesMarkup}
        show={this.state.showNewFeatureAlert}
        toggleAlert={this.toggleNewFeatureAlert}
      />;

    const idleTimerMarkup =
      <IdleTimer
        ref={ref => { this.idleTimer = ref }}
        element={document}
        onActive={this.onActive}
        onIdle={this.onIdle}
        debounce={250}
        timeout={this.timeout}
      />

      const invisibleMarkup =
        <>
          {maxTagAlertMarkup}
          {noTagAlertMarkup}
          {newFeatureAlertMarkup}
          {idleTimerMarkup}
        </>;

    //==========================================================
    // Top Level Markup
    //==========================================================

    return (
      <div className='top'>
        {headerMarkup}
        <hr/>
        {contentMarkup}
        {footerMarkup}
        {invisibleMarkup}
      </div>
    );
  }
}

export default Main;
