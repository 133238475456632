import React, { Component } from 'react';
import _ from 'underscore';

// Components

// filters
import TagFilter from './filters/TagFilter';

// list
import SongList from './list/SongList';

class Editor extends Component {

  constructor(props) {
    super(props);

    // load state from local storage

    // rating filter
    let ratingFilterStorage = localStorage.getItem('ratingFilter');
    if(ratingFilterStorage === null) {
      ratingFilterStorage = [true, true, true, true, true, true];
    }
    else {
      ratingFilterStorage = JSON.parse(ratingFilterStorage);
    }

    // tag filter
    let tagFilterStorage = localStorage.getItem('tagFilter');
    if(tagFilterStorage === null) {
      tagFilterStorage = [];
    }
    else {
      tagFilterStorage = JSON.parse(tagFilterStorage);
      // get rid of any cached tags that have since been deleted
      tagFilterStorage = _.intersection(tagFilterStorage, this.props.tags);
    }

    // setup state
    this.state = {
      ratingFilter: ratingFilterStorage,
      tagFilter: tagFilterStorage,
      tags: [],
    };

    this.deleteSongs = this.deleteSongs.bind(this);
    this.changeAffinity = this.changeAffinity.bind(this);
    this.changeRating = this.changeRating.bind(this);
    this.artistFilter = this.artistFilter.bind(this);
    this.updateRatingFilter = this.updateRatingFilter.bind(this);
    this.titleFilter = this.titleFilter.bind(this);
    this.toggleTagToFilter = this.toggleTagToFilter.bind(this);
    this.toggleAffinityFilter = this.toggleAffinityFilter.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    const tagsUpdated = (!_.isEqual(props.tags, state.tags));

    if(tagsUpdated) {
      const tagRemovedFromFilter = _.intersection(state.tagFilter, props.tags);
      localStorage.setItem('tagFilter', JSON.stringify(tagRemovedFromFilter));
      return {
        tags: props.tags,
        tagFilter: tagRemovedFromFilter,
      };
    }

    return null;
  }

  deleteSongs(songs) {
    //console.time('Deleting songs');
    this.props.deleteSongs(songs);
    //console.timeEnd('Deleting songs');
  }

  changeAffinity(songs, affinity) {
    //console.time('Changing affinity');
    this.props.changeAffinity(songs, affinity);
    //console.timeEnd('Changing affinity');
  }

  changeRating(songs, rating) {
    //console.time('Changing rating');
    this.props.changeRating(songs, rating);
    //console.timeEnd('Changing rating');
  }

  artistFilter(artist) {
    this.setState({artistFilter: artist.toLowerCase()});
  }

  titleFilter(title) {
    this.setState({titleFilter: title.toLowerCase()});
  }

  updateRatingFilter(rating) {
    let newRatingFilter = this.state.ratingFilter;
    newRatingFilter[rating] = !newRatingFilter[rating];

    // update local storage
    localStorage.setItem('ratingFilter', JSON.stringify(newRatingFilter));

    // update state
    this.setState({ratingFilter: newRatingFilter});
  }

  toggleTagToFilter(tag) {
    const fixedTag = tag.toLowerCase();
    const oldTags = this.state.tagFilter;
    let updatedTags = oldTags;
    if(!_.contains(oldTags, tag)) {
      updatedTags.push(tag);
      //console.info('Filtering for only songs with ' + fixedTag);
    }
    else {
      updatedTags = _.without(oldTags, fixedTag);
      //console.info('No longer filtering for songs with ' + fixedTag);
    }
    // update local storage
    localStorage.setItem('tagFilter', JSON.stringify(updatedTags));

    // update state
    this.setState({tagFilter: updatedTags});
  }

  toggleAffinityFilter(affinity) {
    this.props.toggleAffinityFilter(affinity);
  }

  render () {

    const tagFilterMarkup =
      <TagFilter
        tags={this.props.tags}
        toggleTagToFilter={this.toggleTagToFilter}
        tagFilter={this.state.tagFilter}
      />;

    // Songs
    const songListMarkup =
      <SongList
        // data
        songs={this.props.songs}
        tagUnderEdit={this.props.tagUnderEdit}
        ratingFilter={this.state.ratingFilter}
        tagFilter={this.state.tagFilter}
        affinityFilter={this.props.affinityFilter}
        // functions
        deleteSongs={this.deleteSongs}
        changeAffinity={this.changeAffinity}
        changeRating={this.changeRating}
        toggleAffinityFilter={this.toggleAffinityFilter}
        updateRatingFilter={this.updateRatingFilter}
      />;

    return (
      <div className='editor'>
        {tagFilterMarkup}
        <hr/>
        {songListMarkup}
      </div>
    );
  }
}

export default Editor;
