import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

class ConfirmationButton extends Component {

  constructor() {
    super();
    this.state = {show: false};
    this.showBox = this.showBox.bind(this);
    this.closeBox = this.closeBox.bind(this);
    this.confirmChoice = this.confirmChoice.bind(this);
    this.abandonChoice = this.abandonChoice.bind(this);
  }

  showBox() {
    this.setState({show: true});
  }

  closeBox() {
    this.setState({show: false});
  }

  confirmChoice() {
    this.closeBox();
    this.props.functionToExecute();
  }

  abandonChoice() {
    this.closeBox();
  }

  render () {

    // HTML
    const buttonMarkup =
      <Button
        className={this.props.className}
        variant={this.props.variant}
        onClick={async () => await this.showBox()}
      >
        {this.props.buttonName}
      </Button>;

    const boxMarkup =
      <Modal
        size='lg'
        show={this.state.show}
        onHide={this.closeBox}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {this.props.title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.props.bodyText}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='tagifyer-blue'
            onClick={this.confirmChoice}
          >
            Confirm
          </Button>
          <Button
            variant='tagifyer-pink'
            onClick={this.abandonChoice}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>;

    return (
      <div className='confirmationModal'>
        {buttonMarkup}
        {boxMarkup}
      </div>
    );
  }
}

export default ConfirmationButton;
