import spotifyApi from 'spotify-web-api-js';
import error from './errorHelper.js';

import _ from 'underscore';

const spotify = new spotifyApi();

export default class spotifyHelper {

  static userID = '';

  static async getUser() {
    return await spotify.getMe()
    .then((response) => {
      this.userID = response.id;
      return response.id;
    })
    .catch((err) => {
      const message = 'Failed to get user from Spotify';
      error.handle(message, err);
    });
  }

  static setToken(accessToken) {
    spotify.setAccessToken(accessToken);
  }

  static async getNowPlaying() {
    return await spotify.getMyCurrentPlaybackState()
      .then((response) => {
        return response.item.name;
      })
      .catch((err) => {
        const message = 'Failed to get song currently playing';
        error.handle(message, err);
      });
  }

  static async getPlaylists() {
    const playlists = [];
    const user = this.userID;
    let trackRequestState = {
      limit: 20, // Spotify API limit 9/22/2019
      offset: 0,
      fields: 'items',
      total: -1,
    };

    async function getNextPlaylists() {
      const playlists = await spotify.getUserPlaylists(user, trackRequestState)
        .catch((err) => {
          let error_message_from_spotify_package = null;
          if(!err) {
            error_message_from_spotify_package = err;
          }
          const message = 'Failed to get all playlists';
          error.handle(message, error_message_from_spotify_package);
        });
      trackRequestState.total = playlists.total;
      const playlistNames = _.pluck(playlists.items, 'name');
      const playlistIDs = _.pluck(playlists.items, 'id');
      const playlistObjects = _.object(playlistNames, playlistIDs);
      return playlistObjects;
    }

    do {
      const morePlaylists = await getNextPlaylists();
      _.extend(playlists, morePlaylists);
      trackRequestState.offset += trackRequestState.limit;
    } while (trackRequestState.offset < trackRequestState.total);

    return playlists;
  }

  static async getPlaylistID(playlistName) {
    const playlists = await this.getPlaylists();
    if(!playlistName) {
      error.handle('playlistName is undefined in getPlaylistID', null);
    }
    if(!playlists[playlistName]) {
      error.handle('playlists[playlistName] AKA playlistID is undefined in getPlaylistID', null);
    }
    return playlists[playlistName];
  }

  static async getPlaylistSize(playlistID) {
    return await spotify.getPlaylist(playlistID)
      .then((playlist) => {
        return playlist.tracks.total;
      })
      .catch((err) => {
        const message = 'Failed to get playlist size for playlist ID ' + playlistID;
        error.handle(message, err);
      });
  }

  static async getPlaylistSongs(playlist) {
    const playlistTracks = [];

    const playlistID = await this.getPlaylistID(playlist);
    const size = await this.getPlaylistSize(playlistID);

    let trackRequestState = {
      limit: 100, // Spotify API limit 8/13/2019
      offset: 0,
      fields: 'items',
    };

    async function getNextTracks() {
      const playlistTracks = await spotify.getPlaylistTracks(playlistID, trackRequestState)
        .catch((err) => {
          const message = 'Failed to get playlist tracks';
          error.handle(message, err);
        });
      const playlistTrackIDs = playlistTracks.items.map((trackObject) => {
          let id = null;
          if (trackObject.track && trackObject.track.id) {
            id = trackObject.track.id;
          }
          // Don't handle local songs at all, breaks song export
          /*
          else {
            id = trackObject.track.uri;
          }
          */
          if(id) {
            const song = {
              'title' : trackObject.track.name,
              'id'    : id,
              'artist' : trackObject.track.artists[0].name,
              'addedTime' : trackObject.added_at,
            };
            return song;
          }
          return null;
        });
      return _.filter(playlistTrackIDs, (track) => {return track !== null});
    }

    do {
      await Array.prototype.push.apply(playlistTracks, await getNextTracks());
      trackRequestState.offset += trackRequestState.limit;
    } while (trackRequestState.offset < size);

    const dedupPlaylistTracks = _.uniq(playlistTracks, false, _.iteratee((track) => {return track.id}));
    return dedupPlaylistTracks;
  }

  static async getSavedTracks() {
    const playlistTracks = [];

    const savedTracksInfo = await spotify.getMySavedTracks();
    const size = savedTracksInfo.total;

    let trackRequestState = {
      limit: 50, // Spotify API limit 8/13/2019
      offset: 0,
      fields: 'items',
    };

    async function getNextTracks() {
      return await spotify.getMySavedTracks(trackRequestState)
        .then((tracks) => {
          return tracks.items.map((t) => {
            let id = 'null';
            if (!t.track.id) {
              id = t.track.uri;
            } else {
              id = t.track.id;
           }
            const song = {
              'title' : t.track.name,
              'id'    : id,
              'artist' : t.track.artists[0].name,
            };
            return song;
          });
        })
        .catch((err) => {
          const message = 'Failed to get saved tracks';
          error.handle(message, err);
        });
    }

    while (trackRequestState.offset < size) {
      Array.prototype.push.apply(playlistTracks, await getNextTracks());
      trackRequestState.offset += trackRequestState.limit;
    }
    return playlistTracks;
  }

  // Get the names of an array of song IDs
  static async getSongs(songs) {
    return await spotify.getTracks(songs)
      .then((data) => data.tracks.map(function(song) {
        return song.name;
      }))
      .catch((err) => {
        const message = 'Failed to get songs from Spotify API';
        error.handle(message, err);
      });
  };

  static sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  static async addSongsToPlaylist(playlist, songs) {
    const promise = await spotify.addTracksToPlaylist(playlist, songs);
    await this.sleep(100);
    return promise;
  }

  static async createPlaylist(playlistName, songs) {
    let newPlaylistID;
    await spotify.createPlaylist(this.userID, {name: playlistName, public: false})
    .then(res => {
      newPlaylistID = res.id;
    })
    .catch((err) => {
      const message = 'Failed to create new Spotify playlist';
      error.handle(message, err);
    });
    const trackIDs = songs.map(song => {
      return 'spotify:track:' + song.id;
    });
    const chunkSize = 100;
    const chunkedTracks = _.chunk(trackIDs, chunkSize);
    for(let chunkIndex=0; chunkIndex < chunkedTracks.length; chunkIndex++) {
      await this.addSongsToPlaylist(newPlaylistID, chunkedTracks[chunkIndex])
      .catch((err) => {
        const message = 'Failed to add songs to new Spotify playlist';
        error.handle(message, err);
      });
    }
  }
}
