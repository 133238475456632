import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

class ExampleUseCases extends Component {

  constructor() {
    super();

    this.state = {show: false};

    this.showBox = this.showBox.bind(this);
    this.closeBox = this.closeBox.bind(this);
  }

  showBox() {
    this.setState({show: true});
  }

  closeBox () {
    this.setState({show: false});
  }

  render () {

    // text
    const example_1 = 'Tagifyer can help you sort newly discovered music into multiple playlists. Say you find 5 songs you like every week with Discover Weekly. You probably aren\'t always on top of sorting the the high-energy songs to your workout playlist or the chill ones to your relaxed playlist. You can easily see what songs you haven\'t already deemed worthy/unworthy of your workout playlist by selecting your workout playlist and filtering out all the songs with the + or - affinity. That leaves you with exactly the list of songs that needs to be sorted, no more no less. When each playlist is that simple, it\'s more reasonable to keep up with more playlists that are all tuned just to your liking.';
    const example_2 = 'Tagifyer can help you make playlists based on your ratings of songs. Sometimes you only want to listen to the hits - filter out all your songs rated 3 or lower to make a playlist with your 4s and 5s. Maybe you\'re roommate is getting sick of hearing the ten songs you rated a 5 over and over again - make a playlist with 1-4. Mix it up by rating to keep things as fresh as you want.';
    const example_3 = 'Tagifyer can help you make playlists that are combinations of tags and ratings. Maintaining a playlist of music you like per genre isn\'t too hard, but what about making a playlist of the best half of your electronica with the hip-hop classics? Pulling in pieces of different tags into a new tag is easy, either for a one-shot party mix or an eclectic go-to playlist.';

    // HTML
    const buttonMarkup =
      <Button
        variant='tagifyer-menu'
        onClick={async () => await this.showBox()}
      >
        Examples
      </Button>;

    const boxMarkup =
      <Modal
        size='lg'
        show={this.state.show}
        onHide={this.closeBox}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Example Use Cases
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ul>
            <li>
              {example_1}
            </li>
            <li>
              {example_2}
            </li>
            <li>
              {example_3}
            </li>
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='tagifyer-menu'
            onClick={this.closeBox}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>;

    return (
      <div>
        {buttonMarkup}
        {boxMarkup}
      </div>
    );
  }
}

export default ExampleUseCases;
