import * as Sentry from '@sentry/browser';

export default class errorHelper {

  static handle(message, error) {
    console.group('Tagifyer Error Handler');
    console.error(message);
    if(error) {
      console.error(error);
    }
    console.groupEnd('Tagifyer Error Handler');
    Sentry.captureException(message + '\n' + error);
  }

  static log(message) {
    console.group('Tagifyer Message Handler');
    console.warn(message);
    console.groupEnd('Tagifyer Message Handler');
    Sentry.captureMessage(message);
  }
}
