import React, { Component } from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import AlertPopup from '../misc_components/AlertPopup';

class SpotifyExportButton extends Component {

  constructor() {
    super();

    this.state = {
      exportedName: 'Null',
      show: false,
      showTagAlert: false,
    };

    this.exportTag = this.exportTag.bind(this);
    this.closeBox = this.closeBox.bind(this);
    this.toggleAlert = this.toggleAlert.bind(this);
  }

  async exportTag() {
    //console.time('Exporting playlist');
    const exportedName = await this.props.exportTag();
    if(exportedName !== 'tagifyer_null') {
      this.setState({
        exportedName: exportedName,
        show: true,
      });
    }
    else {
      this.toggleAlert();
    }
    //console.timeEnd('Exporting playlist');
  }

  toggleAlert() {
    this.setState({showTagAlert: !this.state.showTagAlert});
  }

  closeBox() {
    this.setState({show: false});
  }

  render () {

    const buttonMarkup =
      <Button
        variant='tagifyer-menu'
        onClick={this.exportTag}
      >
        Export Tag to Spotify Playlist
      </Button>

    const boxMarkup =
      <Modal
        size='md'
        show={this.state.show}
        onHide={this.closeBox}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Tag Exported to Spotify as Playlist
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Exported as playlist {this.state.exportedName}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='tagifyer-menu'
            onClick={this.closeBox}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>;

    const alertPopupMarkup =
      <AlertPopup
        title='No Tag Selected'
        bodyText='Select a tag before trying to export a tag to a Spotify playlist'
        show={this.state.showTagAlert}
        toggleAlert={this.toggleAlert}
      />;

    return (
      <div>
        {buttonMarkup}
        {boxMarkup}
        {alertPopupMarkup}
      </div>
    );
  }
}

export default SpotifyExportButton;
