import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import Main from './Main';
import './index.css';

Sentry.init({dsn: 'https://44c980bbccad4760a82c760f3a7e0071@sentry.io/1515998'});
ReactDOM.render(
  <Main/>,
  document.getElementById('root')
);
