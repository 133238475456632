import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';
import _ from 'underscore';

import AlertPopup from '../../misc_components/AlertPopup';

import error from '../../helpers/errorHelper';

class AffinityButtons extends Component {

  constructor() {
    super();

    this.state = {showAffinityAlert: false};

    this.toggleAlert = this.toggleAlert.bind(this);
  }

  changeAffinity(song, affinity) {
    if(this.props.tagUnderEdit) {
      this.props.changeAffinity([song], affinity);
    }
    else {
      error.log('Start editing a tag before changing the affinity of a song');
      this.toggleAlert(true);
    }
  }

  toggleAlert() {
    this.setState({showAffinityAlert: !this.state.showAffinityAlert});
  }

  render () {
    const tagUnderEdit = this.props.tagUnderEdit;
    const song = this.props.song;

    let plusButton;
    let neutralButton;
    let minusButton;

    const currentPlusAffinity = _.contains(song.plusTags, tagUnderEdit);
    const currentMinusAffinity = _.contains(song.minusTags, tagUnderEdit);
    const currentNeutralAffinity = !(currentPlusAffinity || currentMinusAffinity);

    let plusDeselect = '';
    let neutralDeselect = '';
    let minusDeselect = '';

    if(currentPlusAffinity === false) plusDeselect='outline-';
    if(currentNeutralAffinity === false) neutralDeselect='outline-';
    if(currentMinusAffinity === false) minusDeselect='outline-';

    plusButton =
      <Button
        className='affinityButton'
        variant={plusDeselect + 'tagifyer-blue'}
        onClick={() => this.changeAffinity(song, 'plus')}
      >
        {'+'}
      </Button>;

    neutralButton =
      <Button
        className='affinityButton'
        variant={neutralDeselect + 'tagifyer-white'}
        onClick={() => this.changeAffinity(song, 'neutral')}
      >
        {'?'}
      </Button>;

    minusButton =
      <Button
        className='affinityButton'
        variant={minusDeselect + 'tagifyer-pink'}
        onClick={() => this.changeAffinity(song, 'minus')}
      >
        {'-'}
      </Button>;

    const affinityButtons =
      <div
        className='songListingAffinity'
      >
        {plusButton}
        {neutralButton}
        {minusButton}
      </div>

    const alertPopupMarkup =
      <AlertPopup
        title='No Tag Selected'
        bodyText='Select a tag so you can change the affinity of a song towards that tag'
        show={this.state.showAffinityAlert}
        toggleAlert={this.toggleAlert}
      />;

    return (
      <div className='affinityButtonsGroup'>
        {affinityButtons}
        {alertPopupMarkup}
      </div>
    );
  }
}

export default AffinityButtons;
