import React, { Component } from "react";

class Introduction extends Component {

  render () {
    const para1 = 'Create precisely the playlist you want with Tagifyer. Tag songs with custom labels that make sense to you, then use them to make and maintain exactly the playlist you want.';
    const para2 = 'Want to maintain a workout playlist of all your upbeat songs? A night time playlist of classical music that\'s also calming? A road trip playlist of all Metallica songs that you rate a 4 or 5 plus all the 80s soft rock in your collection? It\'s time consuming to create and maintain these playlists manually, but with Tagifyer it\'s easy. Tag music once and generate a playlist as complex as you want it to be quickly.';
    const para3 = 'Hit the \'Login with Spotify\' button at the top to enter your Spotify credentials and get started. Check out \'Getting Started\' if you\'re lost about what to do next.';
    return (
      <div className='introduction'>
        <p>{para1}</p>
        <p>{para2}</p>
        <p>{para3}</p>
      </div>
    );
  }
}

export default Introduction;
