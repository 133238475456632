import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

class AlertPopup extends Component {

  constructor() {
    super();
    this.toggleAlert = this.toggleAlert.bind(this);
  }

  toggleAlert() {
    this.props.toggleAlert(false);
  }

  render () {
    const boxMarkup =
      <Modal
        size='lg'
        show={this.props.show}
        onHide={this.closeBox}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {this.props.title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.props.bodyText}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='tagifyer-pink'
            onClick={this.toggleAlert}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>;

    return (
      <div className='alertModal'>
        {boxMarkup}
      </div>
    );
  }
}

export default AlertPopup;
