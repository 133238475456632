import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';

class RatingButtons extends Component {

  changeRating(song, rating) {
    this.props.changeRating([song], rating);
  }

  render () {
    const song = this.props.song;

    const ratingSelected = '';
    const ratingNotSelected = 'outline-'

    const ratingButtons =
      <ButtonGroup className='ratingButtonsGroup'>
        <Button
          className='ratingButton'
          variant={((song.rating === 0) ? ratingSelected : ratingNotSelected) + 'tagifyer-blue'}
          onClick={() => this.changeRating(song, 0)}
        >
          0
        </Button>
        <Button
          className='ratingButton'
          variant={((song.rating === 1) ? ratingSelected : ratingNotSelected) + 'tagifyer-blue'}
          onClick={() => this.changeRating(song, 1)}
        >
          1
        </Button>
        <Button
          className='ratingButton'
          variant={((song.rating === 2) ? ratingSelected : ratingNotSelected) + 'tagifyer-blue'}
          onClick={() => this.changeRating(song, 2)}
        >
          2
        </Button>
        <Button
          className='ratingButton'
          variant={((song.rating === 3) ? ratingSelected : ratingNotSelected) + 'tagifyer-blue'}
          onClick={() => this.changeRating(song, 3)}
        >
          3
        </Button>
        <Button
          className='ratingButton'
          variant={((song.rating === 4) ? ratingSelected : ratingNotSelected) + 'tagifyer-blue'}
          onClick={() => this.changeRating(song, 4)}
        >
          4
        </Button>
        <Button
          className='ratingButton'
          variant={((song.rating === 5) ? ratingSelected : ratingNotSelected) + 'tagifyer-blue'}
          onClick={() => this.changeRating(song, 5)}
        >
          5
        </Button>
      </ButtonGroup>;

    return (
      <>
        {ratingButtons}
      </>
    );
  }
}

export default RatingButtons;
