import _ from 'underscore';
import error from './errorHelper.js';

export default class dynamoHelper {

  static tokenUser = 'Guest';
  static dbUser = 'Guest';
  static session = null;
  static token = null;

  static apiGateway = 'u68mnk151a';
  static apiGatewayKey = 'NtVtVIE2tU2018mQXd6ST3tbuU1cL23V34WSCnyn';

  //static stage = 'no stage';
  static stage = (process.env.NODE_ENV === 'development') ? 'latest' : 'production';

  static chunkSize = 25;

  static setUser(user) {
    this.tokenUser = user;
    if(process.env.NODE_ENV === 'development') {
      this.dbUser = 'dev_user_tagifyer';
    }
    else {
      this.dbUser = user;
    }
  }

  static setSession(session) {
    this.session = session;
  }

  static setToken(token) {
    this.token = token;
  }

  static async refreshToken() {
    const fetchString =
        'https://'
      + this.apiGateway
      + '.execute-api.us-east-1.amazonaws.com/'
      + this.stage
      + '/'
      + this.tokenUser
      + '/refreshToken/'
      + this.session
      + '/'
      + this.token;

    return await fetch(fetchString, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': this.apiGatewayKey,
      },
    })
    .then(response => {
      if (response.status === 200) return response.json();
      throw new Error('Failed to refresh Spotify access token');
    })
    .then(accessToken => {
      this.setToken(accessToken);
      //console.info('Refreshed Spotify access token');
      return accessToken;
    })
    .catch(err => {
      const message = 'Failed to refresh Spotify access token, revoking session';
      error.handle(message, err);
      return false;
    });
  }

  static async getUserInfo() {
    const fetchString =
        'https://'
      + this.apiGateway
      + '.execute-api.us-east-1.amazonaws.com/'
      + this.stage
      + '/'
      + this.tokenUser
      + '/getUserInfo/'
      + this.session
      + '/'
      + this.token;

    return await fetch(fetchString, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': this.apiGatewayKey,
      },

    })
    .then(response => {
      if (response.status === 200) return response.json();
      throw new Error('Failed to check user type');
    })
    .then(userInfo => {
      return userInfo;
    })
    .catch(err => {
      const message = 'Failed to check user type, revoking session';
      error.handle(message, err);
      return false;
    });
  }

  static async logoutUser() {
    const user = this.tokenUser;
    const fetchString =
        'https://'
      + this.apiGateway
      + '.execute-api.us-east-1.amazonaws.com/'
      + this.stage
      + '/'
      + user
      + '/logout/'
      + this.session;

    return fetch(fetchString, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': this.apiGatewayKey,
      },
    })
    .then(response => {
      //console.info('Deleted user ' + user + ' from the database');
    })
    .catch(err => {
      const message = 'Failed to logout user ' + user;
      error.handle(message, err);
    });
  }

  static async getDatabase() {
    //console.time('Getting remote database');
    const user = this.dbUser;
    const fetchString =
        'https://'
      + this.apiGateway
      + '.execute-api.us-east-1.amazonaws.com/'
      + this.stage
      + '/'
      + user
      + '/getDatabase/'
      + this.session;

    return await fetch(fetchString, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': this.apiGatewayKey,
      },
    })
    .then(response => {
      if (response.status === 200) return response.json();
      throw new Error(response);
    })
    .then(responseJson => {
      //console.info('Retrieved ' + user + '\'s song database');
      //console.timeEnd('Getting remote database');
      return responseJson;
    })
    .catch(err => {
      const message = 'Failed to retrieve user ' + user + '\'s song database';
      error.handle(message, err);
    });
  }

  // add an array of songs to the user's song database
  static async addSongs(songs) {
    const user = this.dbUser;
    const fetchString =
        'https://'
      + this.apiGateway
      + '.execute-api.us-east-1.amazonaws.com/'
      + this.stage
      + '/'
      + user
      + '/song/update/'
      + this.session;

    const songChunks = _.chunk(songs, this.chunkSize);
    const songChunkPromises = songChunks.map(async (songChunk) => {
      return fetch(fetchString, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': this.apiGatewayKey,
        },
        body: JSON.stringify(songChunk),
      })
      .then(response => {
        if(response.status === 201) {
          _.each(songChunk, (song) => {
            //console.info('Added song ' + song.title + ' to the database');
          });
        }
        else {
          throw(response.status);
        }
      })
      .catch(err => {
        const message = 'Failed to add songs to the user\'s database';
        error.handle(message, err);
      });
    });
    return Promise.all(songChunkPromises);
  }

  static async deleteSongs(songs) {
    const user = this.dbUser;
    const fetchString =
        'https://'
      + this.apiGateway
      + '.execute-api.us-east-1.amazonaws.com/'
      + this.stage
      + '/'
      + user
      + '/song/delete/'
      + this.session;

    const songChunks = _.chunk(songs, this.chunkSize);
    const songChunkPromises = songChunks.map(async (songChunk) => {
      const song_ids = _.pluck(songChunk, 'id');
      return fetch(fetchString, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': this.apiGatewayKey,
        },
        body: JSON.stringify({
          songs: song_ids,
        }),
      })
      .then(response => {
        _.each(songChunk, (song) => {
          //console.info('Deleted song ' + song.name + ' from the database');
        });
      })
      .catch(err => {
        const message = 'Failed to delete song for user ' + user;
        error.handle(message, err);
      });
    });
    return Promise.all(songChunkPromises);
  }

  // delete all the user's songs
  static async deleteAllSongs() {
    const allSongs = await dynamoHelper.getDatabase();
    await this.deleteSongs(allSongs);
    //console.info('Deleted all of ' + this.dbUser + '\'s songs');
  }

  static async updateDatabaseSignature(signature) {
    const user = this.dbUser;
    const fetchString =
        'https://'
      + this.apiGateway
      + '.execute-api.us-east-1.amazonaws.com/'
      + this.stage
      + '/'
      + user
      + '/databaseSignature';

    return fetch(fetchString, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': this.apiGatewayKey,
      },
      body: JSON.stringify({
        signature: signature,
      }),
    })
    .then(response => {
      if (response.status === 201) return response.json();
      throw new Error();
    })
    .then(responseJson => {
      //console.info('Updated the remote database signature');
    })
    .catch(err => {
      const message = 'Failed to add the remote database signature for user ' + user;
      error.handle(message, err);
    });
  }

  static async getDatabaseSignature() {
    const user = this.dbUser;
    const fetchString =
        'https://'
      + this.apiGateway
      + '.execute-api.us-east-1.amazonaws.com/'
      + this.stage
      + '/'
      + user
      + '/databaseSignature';

    return await fetch(fetchString, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': this.apiGatewayKey,
      },
    })
    .then(response => {
      if (response.status === 200) return response.json();
      throw new Error();
    })
    .then(responseJson => {
      //console.info('Retrieved the remote database signature');
      return (responseJson.length > 0) ? responseJson[0].signature : null;
    })
    .catch(err => {
      const message = 'Failed to get the remote database signature for user ' + user;
      error.handle(message, err);
    });
  }

}
