import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

class GettingStarted extends Component {

  constructor() {
    super();

    this.state = {show: false};

    this.showBox = this.showBox.bind(this);
    this.closeBox = this.closeBox.bind(this);
  }

  showBox() {
    this.setState({show: true});
  }

  closeBox () {
    this.setState({show: false});
  }

  render () {

    // text
    const block_1a = 'A ';
    const block_1b = 'tag';
    const block_1c = ' is how you group songs together in Tagifyer in order to export them to a Spotify playlist. Your tags will be saved in Tagifyer, so you can easily add or remove songs and update your playlist.';
    const block_2a = 'Songs have an ';
    const block_2b = 'association';
    const block_2c = ' with each tag. Songs can either have a positive (+), negative (-), or neutral (?) association with a tag. A positive association means the song is part of that tag, a negative association means it\'s definitely not part of that tag, and a neutral association means you haven\'t decided yet. You can change a song\'s association with the tag you\'re editing between any three of these options at any point.';
    const block_3 = 'What should you do first?';
    const block_4 = 'After logging in, import some playlists from Spotify into Tagifyer. Create a tag, and then start changing the associations of songs for that tag. Once you have the songs you want positively associated with your tag, export the tag to make a Spotify playlist of the positively associated songs.';

    // HTML
    const buttonMarkup =
      <Button
        variant='tagifyer-menu'
        onClick={async () => await this.showBox()}
      >
        Getting Started
      </Button>;

    const boxMarkup =
      <Modal
        size='lg'
        show={this.state.show}
        onHide={this.closeBox}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Getting Started
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            {block_1a}
            <b className='pinkText'>
              {block_1b}
            </b>
            {block_1c}
          </p>
          <p>
            {block_2a}
            <b className='pinkText'>
              {block_2b}
            </b>
            {block_2c}
          </p>
          <p className='pinkText'>
            <b>
              {block_3}
            </b>
          </p>
          <p>
            {block_4}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='tagifyer-menu'
            onClick={this.closeBox}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>;

    return (
      <div>
        {buttonMarkup}
        {boxMarkup}
      </div>
    );
  }
}

export default GettingStarted;
