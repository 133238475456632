import React, { Component } from "react";
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Button from 'react-bootstrap/Button';
import _ from 'underscore';

class RatingFilter extends Component {

  updateRatingFilter(rating) {
    this.props.updateRatingFilter(rating);
  }

  render () {
    const ratingFilter = this.props.ratingFilter;

    let ratingButtons = [];
    let buttonIndex = 0;

    _.each(ratingFilter, (filter) => {
      const selected = (filter) ? '' : 'outline-';
      const buttonValue = buttonIndex;
      ratingButtons.push(
        <Button
          className='ratingButton'
          variant={selected + 'tagifyer-blue'}
          key={buttonIndex}
          onClick={() => this.updateRatingFilter(buttonValue)}
        >
          {buttonIndex}
        </Button>
      );
      buttonIndex++;
    });

    const ratingButtonGroup =
      <ButtonGroup>
        {ratingButtons}
      </ButtonGroup>

    return (
      <div>
          {ratingButtonGroup}
      </div>
    );
  }
}

export default RatingFilter;
