import React, { Component } from 'react';

// Components
import GettingStarted from './GettingStarted';
import UserGuide from './UserGuide';
import ExampleUseCases from './ExampleUseCases';
import SpotifyLoginButton from './SpotifyLoginButton';
import SpotifyLogoutButton from './SpotifyLogoutButton';
import SpotifyExportButton from './SpotifyExportButton';
import SpotifyImportButton from './SpotifyImportButton';

class Menu extends Component {

  constructor() {
    super();
    this.logout = this.logout.bind(this);
    this.getDatabase = this.getDatabase.bind(this);
    this.addSongsLocal = this.addSongsLocal.bind(this);
    this.exportTag = this.exportTag.bind(this);
  }

  logout() {
    this.props.logout();
  }

  getDatabase() {
    this.props.getDatabase();
  }

  addSongsLocal(songs) {
    this.props.addSongsLocal(songs);
  }

  async exportTag() {
    return await this.props.exportTag();
  }

  render () {
    const authenticated = this.props.authenticated;

    // logged in buttons
    const spotifyImportMarkup =
      <div className='menuItem'>
        <SpotifyImportButton
          playlists={this.props.playlists}
          songs={this.props.songs}
          maxSongs={this.props.maxSongs}
          getDatabase={this.getDatabase}
          addSongsLocal={this.addSongsLocal}
        />
      </div>;

    const spotifyExportMarkup =
      <div className='menuItem'>
        <SpotifyExportButton
          exportTag={this.exportTag}
        />
      </div>;

    const logoutMarkup =
      <div className='menuItem'>
        <SpotifyLogoutButton
          logout={this.logout}
        />
      </div>;

    // logged out buttons
    const loginMarkup =
      <div className='menuItem'>
        <SpotifyLoginButton/>
      </div>;

    // buttons for logged in and logged out
    const gettingStartedMarkup =
      <div className='menuItem'>
        <GettingStarted/>
      </div>;

    const userGuideMarkup =
      <div className='menuItem'>
        <UserGuide/>
      </div>;

    const examplesMarkup =
      <div className='menuItem'>
        <ExampleUseCases/>
      </div>;

    // render the menu
    const loggedInMenuMarkup =
      <div className='menu'>
        {gettingStartedMarkup}
        {userGuideMarkup}
        {examplesMarkup}
        {spotifyImportMarkup}
        {spotifyExportMarkup}
        {logoutMarkup}
      </div>;

    const loggedOutMenuMarkup =
      <div className='menu'>
        {gettingStartedMarkup}
        {userGuideMarkup}
        {examplesMarkup}
        {loginMarkup}
      </div>;

    const menuMarkup = (authenticated) ? loggedInMenuMarkup : loggedOutMenuMarkup;

    return (
      <>
        {menuMarkup}
      </>
    );
  }
}

export default Menu;
