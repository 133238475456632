import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';
import _ from 'underscore';

class TagFilter extends Component {

  toggleTagToFilter(tag) {
    this.props.toggleTagToFilter(tag);
  }

  render () {

    const tags = this.props.tags;
    const tagFilter = this.props.tagFilter;

    let tagSelect = [];

    _.each(tags, (tag) => {
      if(_.contains(tagFilter, tag)) {
        tagSelect.push({
          name: tag,
          select: '',
        });
      }
      else {
        tagSelect.push({
          name: tag,
          select: 'outline-',
        });
      }
    });

    let tagButtons = '';

    if(_.size(tagSelect)) {
      tagButtons = _.sortBy(tagSelect, 'name').map((tag) =>
        <Button
          className='tagButton'
          variant={tag.select + 'tagifyer-blue'}
          key={tag.name}
          onClick={() => this.toggleTagToFilter(tag.name)}
        >
          {'#' + tag.name}
        </Button>
    )};

    const tagFilterMarkup = (this.props.tags.length > 1) ?
      <div className='tagFilter'>
        <div className='tagsLabel'>
          Tag Filter - Select tags to display only songs related to those tags
        </div>
        <div className='tagFilterButtons'>
          {tagButtons}
        </div>
      </div>
      : '';

    return (
      <>
        {tagFilterMarkup}
      </>
    );
  }
}

export default TagFilter;
