import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import Button from 'react-bootstrap/Button';

import VisibleSongsDelete from './VisibleSongsDelete';
import VisibleSongsAffinity from './VisibleSongsAffinity';
import VisibleSongsRating from './VisibleSongsRating';
import AffinityButtons from './AffinityButtons';
import RatingButtons from './RatingButtons';
import AffinityFilter from '../filters/AffinityFilter';
import RatingFilter from '../filters/RatingFilter';

import _ from 'underscore';
import cloneDeep from 'lodash/cloneDeep';

class SongList extends Component {

  constructor(props) {
    super(props);

    this.state = {
      songs: [],
      tagUnderEdit: '',
    };

    this.deleteSongs = this.deleteSongs.bind(this);
    this.changeAffinity = this.changeAffinity.bind(this);
    this.changeRating = this.changeRating.bind(this);
    this.updateRatingFilter = this.updateRatingFilter.bind(this);
    this.toggleAffinityFilter = this.toggleAffinityFilter.bind(this);
    this.songPassesAffinityFilter = this.songPassesAffinityFilter.bind(this);
    this.filterSongsForTable = this.filterSongsForTable.bind(this);
    this.filterSongsForOperations = this.filterSongsForOperations.bind(this);
  }

  deleteSongs(songs) {
    this.props.deleteSongs(songs);
  }

  changeAffinity(songs, affinity) {
    this.props.changeAffinity(songs, affinity);
  }

  changeRating(songs, rating) {
    this.props.changeRating(songs, rating);
  }

  updateRatingFilter(rating) {
    this.props.updateRatingFilter(rating);
  }

  toggleAffinityFilter(affinity) {
    this.props.toggleAffinityFilter(affinity);
  }

  songPassesArtistFilter(song) {
    if(this.node) {
      const currentFilter = this.node.filterContext.currFilters;
      let artistFilter = '';
      if(currentFilter.artist) {
        artistFilter = currentFilter.artist.filterVal;
        if(artistFilter) {
          artistFilter = artistFilter.toLowerCase();
        }
      }
      return song.artist.toLowerCase().includes(artistFilter);
    }
    return true;
  }

  songPassesTitleFilter(song) {
    if(this.node) {
      const currentFilter = this.node.filterContext.currFilters;
      let titleFilter = '';
      if(currentFilter.name) {
        titleFilter = currentFilter.name.filterVal;
        if(titleFilter) {
          titleFilter = titleFilter.toLowerCase();
        }
      }
      return song.name.toLowerCase().includes(titleFilter);
    }
    return true;
  }

  songPassesAffinityFilter(song) {
    const affinityFilter = this.props.affinityFilter;
    if(affinityFilter.plus && affinityFilter.minus && affinityFilter.neutral) {
      return true;
    }
    const currentTag = this.state.tagUnderEdit;
    const plusSong = _.contains(song.plusTags, currentTag);
    const minusSong = _.contains(song.minusTags, currentTag);
    const neutralSong = !(plusSong || minusSong);
    return (
          (affinityFilter.plus && plusSong)
      ||  (affinityFilter.minus && minusSong)
      ||  (affinityFilter.neutral && neutralSong)
    );
  }

  songPassesRatingFilter(song) {
    const ratingFilter = this.props.ratingFilter;
    return ratingFilter[song.rating];
  }

  songPassesTagFilter(song) {
    const filterTags = this.props.tagFilter;
    if(!filterTags || filterTags.length === 0) {
      return true;
    }
    return _.some(song.plusTags, (plusTag) => _.contains(filterTags, plusTag));
  }

  songPassesAllFilters(song) {
    return (
          this.songPassesArtistFilter(song)
      &&  this.songPassesTitleFilter(song)
      &&  this.songPassesAffinityFilter(song)
      &&  this.songPassesRatingFilter(song)
      &&  this.songPassesTagFilter(song)
    );
  }

  filterSongsForTable(songs) {
    //console.time('Filtering songs');
    let filteredArray = [];
    if(songs) {
      filteredArray = _.filter(songs, (song) => {
        if(
                this.songPassesAffinityFilter(song)
            &&  this.songPassesRatingFilter(song)
            &&  this.songPassesTagFilter(song)
        ) {
          return song;
        }
        return null;
      });
    }
    //console.timeEnd('Filtering songs');
    return filteredArray;
  }

  filterSongsForOperations(songs) {
    //console.time('Filtering songs');
    const partiallyFilteredSongs = this.filterSongsForTable(songs);
    let filteredArray = [];
    if(partiallyFilteredSongs) {
      filteredArray = _.filter(partiallyFilteredSongs, (song) => {
        if(
                this.songPassesArtistFilter(song)
            &&  this.songPassesTitleFilter(song)
        ) {
          return song;
        }
        return null;
      });
    }
    //console.timeEnd('Filtering songs');
    return filteredArray;
  }

  static getDerivedStateFromProps(props, state) {
    const songsUpdated = (!_.isEqual(props.songs, state.songs));
    const tagUnderEditUpdated = (props.tagUnderEdit !== state.tagUnderEdit);

    if(songsUpdated || tagUnderEditUpdated) {
      // Do a deep clone of props to give to state
      // Otherwise when props changes state is just pointing at props, so it changes
      // React then doesn't know to re-render because it didn't know state changed
      const songsCopy = cloneDeep(props.songs);
      return {
        songs: songsCopy,
        tagUnderEdit: props.tagUnderEdit,
      };
    }
    return null;
  }

  render () {

    const tableSongs = this.filterSongsForTable(this.state.songs);

    //============================================================
    // Song Table
    //============================================================

    const currentSongsCount = this.filterSongsForOperations(this.state.songs).length;
    const plural = (currentSongsCount !== 1) ? 's' : '';
    const numSongMarkup = (this.state.songs) ?
      'Currently Editing ' + currentSongsCount + ' Song' + plural
      : '';

    const titleHeaderFormatter = (column, colIndex, {sortElement, filterElement}) => {
      const titleHeaderMarkup =
        <>
          <div className='songsHeader'>
            <h3 className='songsTitle'>
              Songs
            </h3>
            <div className='numberOfSongs'>
              {numSongMarkup}
            </div>
          </div>
          <div className='titleHeader'>
            {'Filter by ' + column.text}
            {filterElement}
          </div>
          {column.text}
          &nbsp;
          {sortElement}
        </>;
      return titleHeaderMarkup;
    }

    const titleFormatter = (cell, row, rowIndex, extraData) => {
      const titleMarkup =
        <a
          href={'https://open.spotify.com/track/' + row.id}
          className='songLink'
          target='_blank'
          rel='noopener noreferrer'
        >
          {cell}
        </a>;
      return titleMarkup;
    }

    const artistHeaderFormatter = (column, colIndex, {sortElement, filterElement}) => {
      const artistHeaderMarkup =
        <>
          <VisibleSongsDelete
            visibleSongs={this.filterSongsForOperations(this.state.songs)}
            deleteSongs={this.deleteSongs}
            visibleSongsCount={currentSongsCount}
          />
          <div className='artistHeader'>
            {'Filter by ' + column.text}
            {filterElement}
          </div>
          {column.text}
          &nbsp;
          {sortElement}
        </>;
      return artistHeaderMarkup;
    }

    const artistFormatter = (cell, row, rowIndex, extraData) => {
      const artistMarkup =
        <i>
          {cell}
        </i>;
      return artistMarkup;
    }

    const affinityHeaderFormatter = () => {
      const affinityHeaderMarkup =
        <>
          <div className='affinityHeader'>
            <div className='affinityFilterHeading'>
              {'Update All Current Tag Associations (' + currentSongsCount + ')'}
            </div>
            <VisibleSongsAffinity
              visibleSongs={this.filterSongsForOperations(this.state.songs)}
              tagUnderEdit={this.props.tagUnderEdit}
              changeAffinity={this.props.changeAffinity}
            />
          </div>
          <div className='affinityHeader'>
            <div className='affinityFilterHeading'>
              Filter by Tag Association
            </div>
            <AffinityFilter
              affinityFilter={this.props.affinityFilter}
              toggleAffinityFilter={this.toggleAffinityFilter}
            />
          </div>
          <div className='affinityFilterHeading'>
            Update Single Tag Association
          </div>
        </>;
      return affinityHeaderMarkup;
    }

    const affinityFormatter = (cell, row, rowIndex, extraData) => {
      const song = row;
      const tagUnderEdit = extraData.tagUnderEdit;
      const affinityButtonsMarkup =
        <AffinityButtons
          key={song.id}
          song={song}
          tagUnderEdit={tagUnderEdit}
          changeAffinity={this.changeAffinity}
        />;
      return affinityButtonsMarkup;
    }

    const ratingHeaderFormatter = () => {
      const ratingHeaderMarkup =
        <>
          <div className='ratingHeader'>
            <div className='ratingFilterHeading'>
              {'Update All Current Ratings (' + currentSongsCount + ')'}
            </div>
            <VisibleSongsRating
              visibleSongs={this.filterSongsForOperations(this.state.songs)}
              tagUnderEdit={this.props.tagUnderEdit}
              changeRating={this.props.changeRating}
            />
          </div>
          <div className='ratingHeader'>
            <div className='ratingFilterHeading'>
              Filter by Rating
            </div>
            <RatingFilter
              ratingFilter={this.props.ratingFilter}
              updateRatingFilter={this.updateRatingFilter}
            />
          </div>
          <div className='ratingFilterHeading'>
            Update Single Rating
          </div>
        </>;
      return ratingHeaderMarkup;
    }

    const ratingFormatter = (cell, row, rowIndex) => {
      const song = row;
      const ratingButtonsMarkup =
        <RatingButtons
          key={song.id}
          song={song}
          tagUnderEdit={this.state.tagUnderEdit}
          changeRating={this.changeRating}
        />;
      return ratingButtonsMarkup;
    }

    const sortCarets = (order, column) => {
      if(order === 'desc') {
        const upMarkup =
          <>&#x25B2;</>;
        return upMarkup;
      }
      else if(order === 'asc') {
        const upMarkup =
          <>&#x25BC;</>;
        return upMarkup;
      }
      else {
        return '';
      }
    }

    const columns = [{
      dataField: 'name',
      text: 'Title',
      filter: textFilter({
        placeholder: 'Enter title to filter by',
        onFilter: (filterVal) => {
          if(filterVal !== this.state.titleFilter) {
            this.setState({titleFilter: filterVal});
          }
        },
      }),
      headerFormatter: titleHeaderFormatter,
      formatter: titleFormatter,
      sort: true,
      sortCaret: sortCarets,
    }, {
      dataField: 'artist',
      text: 'Artist',
      filter: textFilter ({
        placeholder: 'Enter artist to filter by',
        onFilter: (filterVal) => {
          if(filterVal !== this.state.artistFilter) {
            this.setState({artistFilter: filterVal});
          }
        },
      }),
      headerFormatter: artistHeaderFormatter,
      formatter: artistFormatter,
      sort: true,
      sortCaret: sortCarets,
    }, {
      dataField: 'affinity',
      text: 'Affinity',
      align: 'center',
      headerAlign: 'center',
      headerFormatter: affinityHeaderFormatter,
      formatter: affinityFormatter,
      formatExtraData: {tagUnderEdit: this.state.tagUnderEdit},
    }, {
      dataField: 'rating',
      text: 'Rating',
      align: 'center',
      headerAlign: 'center',
      headerFormatter: ratingHeaderFormatter,
      formatter: ratingFormatter,
    }];

    const defaultSorted = [{
      dataField: 'name',
      order: 'asc',
    }];

    const pageButtonRenderer = ({
      page,
      active,
      disabled,
      title,
      onPageChange
    }) => {
      const handleClick = (e) => {
      e.preventDefault();
      onPageChange(page);
      };

      const buttonVariant = (active) ? 'tagifyer-white' : 'tagifyer-pink';

      return (
        <li
          className="page-item"
          key={page}
        >
          <Button
            variant={buttonVariant}
            onClick={handleClick}
          >
            {page}
          </Button>
        </li>
      );
    };

    const sizePerPageRenderer = ({
      options,
      currSizePerPage,
      onSizePerPageChange
    }) => (
      <div className="btn-group" role="group">
        {
          options.map((option) => {
            const isSelect = currSizePerPage === `${option.page}`;
            return (
              <button
                key={ option.text }
                type="button"
                onClick={ () => onSizePerPageChange(option.page) }
                className={ `btn ${isSelect ? 'btn-tagifyer-blue' : 'btn-tagifyer-white'}` }
              >
                { option.text }
              </button>
            );
          })
        }
      </div>
    );

    const pagination = paginationFactory({
      showTotal: true,
      sizePerPage: 25,
      sizePerPageList: [10, 25, 50, 100],
      alwaysShowAllBtns: true,
      pageButtonRenderer,
      sizePerPageRenderer,
    });

    const noData = () => {
      let noDataString = 'No songs match your filters';
      if(!this.state.songs || this.state.songs.length === 0) {
        noDataString = 'No songs to show, import songs from Spotify to get started';
      }
      return (
        <div className='noData'>
          {noDataString}
        </div>
      );
    }

    const tableMarkup =
      <BootstrapTable
        ref={n => this.node = n}
        // data
        keyField='id'
        data={tableSongs}
        columns={columns}
        // functions
        defaultSorted={defaultSorted}
        pagination={pagination}
        filter={filterFactory()}
        noDataIndication={noData}
        // style
        striped={true}
        bordered={false}
        condensed={true}
      />;

    const songListMarkup =
      <div className='songList'>
        {tableMarkup}
      </div>;

    return (
      <div>
        {songListMarkup}
      </div>
    );
  }
}

export default SongList;
