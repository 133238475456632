import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

class UserGuide extends Component {

  constructor() {
    super();
    this.state = {show: false};
    this.showBox = this.showBox.bind(this);
    this.closeBox = this.closeBox.bind(this);
  }

  showBox() {
    this.setState({show: true});
  }

  closeBox () {
    this.setState({show: false});
  }

  render () {

    // text
    const step_1 = 'Click on the "Login with Spotify" button to connect with your Spotify account.';
    const step_2 = 'Click on the "Import Playlist" button and pick a playlist. Tagifyer will pull all the songs currently in that playlist and allow you to add tags to them.';
    const step_3 = 'Create a tag by making a tag name such as "workout" and entering it in the "Name of new tag" field and clicking the "Create!" button.';
    const step_4 = 'Select the tag you want to add songs to by selecting your new tag next to the "Currently editing" text. NOT where it says "Tag Filter" - we\'ll get to that in a second.';
    const step_5 = 'You should now see all of your imported songs with their current affinity towards the tag you just made. A song\'s tag affinity to a tag is whether or not it\'s a part of that tag. A "+" affinity means the song is part of that tag, a "-" affinity mean it\'s explicitly not part of that tag, and  a "?" affinity means you haven\'t yet told tagifyer if it is or isn\'t part of that tag. You haven\'t changed any affinities yet, so all songs have "?" affinity to your new tag. Go ahead and start clicking on the +/?/- buttons next to each song to add/ignore/remove songs for this tag.';
    const step_6 = 'In addition to giving a song an affinity to each of your tags, you can also give it an overall rating. To give a song a rating click one of the buttons 1-5 next to it. You can change this rating at any time, or click the rating it currently has to remove the rating altogether.';
    const step_7a = 'To modify the tags and ratings more efficiently, you can filter out which imported songs you can currently see with filters. This doesn\'t remove the songs from Tagifyer or change how songs are associated with tags. Use filters to work with a more manageable set of songs at a time. For example if you want to give every song an affinity of either + or - to your new tag, you can deselt the + and -  affinities in the Affinity Filter. Now as you assign + or - affinities to each song for this tag, they will be filtered out and you will only see the songs you haven\'t sorted yet.';
    const step_7b = 'For example if you want to give every song an affinity of either + or - to your new tag, you can deselt the + and -  affinities in the Affinity Filter. Now as you assign + or - affinities to each song for this tag, they will be filtered out and you will only see the songs you haven\'t sorted yet.';
    const step_7c = 'Additionally you can use the tag filter to ignore songs with a + affinity to a certain tag. If you are trying to make a sleep playlist and you already have a workout playlist, you might know that all your workout songs aren\'t appropriate for your sleep playlist. You could then deselct the workout tag to filter out all those songs.';
    const step_8 = 'After you have edited a tag to your liking, you can export the tag into a Spotify playlist by clicking the "Export Spotify Playlist" button at the top. It will create a playlist with the name "tagifyer_<tag name>';

    const notes_1 = 'If you update a playlist on Spotify after importing it into Tagifyer, those changes will not be automatically reflected in Tagifyer. Import the playlist again to add the new songs into Tagifyer. Deleted songs will not be removed from Tagifyer, but you can delete them from Tagifyer individually with the \'X\' on each song listing.';

    const notes_2 = 'If you import a song through a playlist that Tagifyer is already tracking, it won\'t reset the the Tagifyer information (such as tags, ratings) you have set for the song.';

    const notes_3 = 'You can create up to 5 tagifyer tags and import up to 25,000 songs. Let us know with the link at the bottom of the page if this is an issue.';

    // HTML
    const buttonMarkup =
      <Button
        variant='tagifyer-menu'
        onClick={async () => await this.showBox()}
      >
        User Guide
      </Button>;

    const boxMarkup =
      <Modal
        size='lg'
        show={this.state.show}
        onHide={this.closeBox}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            User Guide
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ol>
            <li>
              {step_1}
            </li>
            <li>
              {step_2}
            </li>
            <li>
              {step_3}
            </li>
            <li>
              {step_4}
            </li>
            <li>
              {step_5}
            </li>
            <li>
              {step_6}
            </li>
            <li>
              {step_7a}
              {step_7b}
              {step_7c}
            </li>
            <li>
              {step_8}
            </li>
          </ol>
          <b className='pinkText'>
            Additional Notes
          </b>
          <ul className='popupList'>
            <li>
              {notes_1}
            </li>
            <li>
              {notes_2}
            </li>
            <li>
              {notes_3}
            </li>
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='tagifyer-menu'
            onClick={this.closeBox}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>;

    return (
      <div>
        {buttonMarkup}
        {boxMarkup}
      </div>
    );
  }
}

export default UserGuide;
