import React, { Component } from 'react';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import _ from 'underscore';

import ConfirmationButton from '../../misc_components/ConfirmationButton';

class VisibleSongsRating extends Component {

  constructor(props) {
    super(props);

    this.changeRating = this.changeRating.bind(this);
  }

  changeRating(rating) {
    this.props.changeRating(this.props.visibleSongs, rating);
  }

  render () {
    const visibleSongs = this.props.visibleSongs;

    let allRatings = 'null';
    const numUniqueRatings = _.size(_.uniq(_.pluck(visibleSongs, 'rating')));
    if(numUniqueRatings === 1) {
      allRatings = visibleSongs[0].rating;
    }

    const ratingSelected = '';
    const ratingNotSelected = 'outline-'

    const ratingButtonsMarkup =
      <ButtonGroup className='songListingRating'>
        <ConfirmationButton
          className='ratingButton'
          variant={((allRatings === 0) ? ratingSelected : ratingNotSelected) + 'tagifyer-blue'}
          buttonName='0'
          title='Change Rating of Visible Songs'
          bodyText={'Confirm you want to change the rating for all visible songs'}
          functionToExecute={() => this.changeRating(0)}
        />
        <ConfirmationButton
          className='ratingButton'
          variant={((allRatings === 1) ? ratingSelected : ratingNotSelected) + 'tagifyer-blue'}
          buttonName='1'
          title='Change Rating of Visible Songs'
          bodyText={'Confirm you want to change the rating for all visible songs'}
          functionToExecute={() => this.changeRating(1)}
        />
        <ConfirmationButton
          className='ratingButton'
          variant={((allRatings === 2) ? ratingSelected : ratingNotSelected) + 'tagifyer-blue'}
          buttonName='2'
          title='Change Rating of Visible Songs'
          bodyText={'Confirm you want to change the rating for all visible songs'}
          functionToExecute={() => this.changeRating(2)}
        />
        <ConfirmationButton
          className='ratingButton'
          variant={((allRatings === 3) ? ratingSelected : ratingNotSelected) + 'tagifyer-blue'}
          buttonName='3'
          title='Change Rating of Visible Songs'
          bodyText={'Confirm you want to change the rating for all visible songs'}
          functionToExecute={() => this.changeRating(3)}
        />
        <ConfirmationButton
          className='ratingButton'
          variant={((allRatings === 4) ? ratingSelected : ratingNotSelected) + 'tagifyer-blue'}
          buttonName='4'
          title='Change Rating of Visible Songs'
          bodyText={'Confirm you want to change the rating for all visible songs'}
          functionToExecute={() => this.changeRating(4)}
        />
        <ConfirmationButton
          className='ratingButton'
          variant={((allRatings === 5) ? ratingSelected : ratingNotSelected) + 'tagifyer-blue'}
          buttonName='5'
          title='Change Rating of Visible Songs'
          bodyText={'Confirm you want to change the rating for all visible songs'}
          functionToExecute={() => this.changeRating(5)}
        />
      </ButtonGroup>;

    return (
      <div className='visibleSongsRating'>
        {ratingButtonsMarkup}
      </div>
    );
  }
}

export default VisibleSongsRating;
