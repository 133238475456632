import React, { Component } from "react";
import Button from 'react-bootstrap/Button';

class AffinityFilter extends Component {

  toggleAffinityFilter(affinity) {
    this.props.toggleAffinityFilter(affinity);
  }

  render() {

    const affinityFilter = this.props.affinityFilter;

    let plusDeselect = '';
    let neutralDeselect = '';
    let minusDeselect = '';

    if(affinityFilter.plus === false) plusDeselect='outline-';
    if(affinityFilter.neutral === false) neutralDeselect='outline-';
    if(affinityFilter.minus === false) minusDeselect='outline-';

    return (
      <div>
        <Button
          className='affinityButton'
          variant={plusDeselect + 'tagifyer-blue'}
          onClick={() => this.toggleAffinityFilter('plus')}
        >
          +
        </Button>
        <Button
          className='affinityButton'
          variant={neutralDeselect + 'tagifyer-white'}
          onClick={() => this.toggleAffinityFilter('neutral')}
        >
          ?
        </Button>
        <Button
          className='affinityButton'
          variant={minusDeselect + 'tagifyer-pink'}
          onClick={() => this.toggleAffinityFilter('minus')}
        >
          -
        </Button>
      </div>
    );
  }
}

export default AffinityFilter;
