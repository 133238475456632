import React, { Component } from 'react';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import _ from 'underscore';

import ConfirmationButton from '../../misc_components/ConfirmationButton';

class VisibleSongsAffinity extends Component {

  constructor(props) {
    super(props);

    this.changeAffinity = this.changeAffinity.bind(this);
  }

  changeAffinity(affinity) {
    this.props.changeAffinity(this.props.visibleSongs, affinity);
  }

  render () {
    const tagUnderEdit = this.props.tagUnderEdit;
    const visibleSongs = this.props.visibleSongs;

    // Affinity

    let allAffinities = 'null';
    if(this.props.visibleSongs.length > 0 && _.every(visibleSongs, (song) => {
      return _.contains(song.plusTags, tagUnderEdit);
    })) {
      allAffinities = 'plus';
    }
    else if(this.props.visibleSongs.length > 0 && _.every(visibleSongs, (song) => {
      return _.contains(song.minusTags, tagUnderEdit);
    })) {
      allAffinities = 'minus';
    }
    else if(this.props.visibleSongs.length > 0 && _.every(visibleSongs, (song) => {
      return (
            !(_.contains(song.minusTags, tagUnderEdit))
        &&  !(_.contains(song.plusTags, tagUnderEdit))
      );
    })) {
      allAffinities = 'neutral';
    }

    const currentPlusAffinity = (allAffinities === 'plus');
    const currentNeutralAffinity = (allAffinities === 'neutral');
    const currentMinusAffinity = (allAffinities === 'minus');

    let plusDeselect = '';
    let neutralDeselect = '';
    let minusDeselect = '';

    if(currentPlusAffinity === false) plusDeselect='outline-';
    if(currentNeutralAffinity === false) neutralDeselect='outline-';
    if(currentMinusAffinity === false) minusDeselect='outline-';

    const plusButton =
      <ConfirmationButton
        className='affinityButton'
        variant={plusDeselect + 'tagifyer-blue'}
        buttonName='+'
        title='Change Affinity of Visible Songs'
        bodyText={'Confirm you want to change the affinty for all visible songs'}
        functionToExecute={() => this.changeAffinity('plus')}
      />

    const neutralButton =
      <ConfirmationButton
        className='affinityButton'
        variant={neutralDeselect + 'tagifyer-white'}
        buttonName='?'
        title='Change Affinity of Visible Songs'
        bodyText={'Confirm you want to change the affinty for all visible songs'}
        functionToExecute={() => this.changeAffinity('plus')}
      />

    const minusButton =
      <ConfirmationButton
        className='affinityButton'
        variant={minusDeselect + 'tagifyer-pink'}
        buttonName='-'
        title='Change Affinity of Visible Songs'
        bodyText={'Confirm you want to change the affinty for all visible songs'}
        functionToExecute={() => this.changeAffinity('minus')}
      />

    const affinityButtonsMarkup =
      <ButtonGroup className='songListingAffinity'>
        {plusButton}
        {neutralButton}
        {minusButton}
      </ButtonGroup>;

    return (
      <div className='VisibleButtonsAffinity'>
        {affinityButtonsMarkup}
      </div>
    );
  }
}

export default VisibleSongsAffinity;
