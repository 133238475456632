import React, { Component } from "react";
import Button from 'react-bootstrap/Button';

class SpotifyLogoutButton extends Component {

  handleLogoutClick = () => {
    this.props.logout();
  }

  render () {
    return (
      <Button
        variant='tagifyer-menu'
        onClick={this.handleLogoutClick}
      >
        Logout
      </Button>
    );
  }
}

export default SpotifyLogoutButton;
